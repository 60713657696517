<template>
  <div class="main_content align-items-center" style="min-height: 80vh">
    <div class="row justify-content-center">
      <div class="row justify-content-between mb_54">
        <div class="col-md-12 col-lg-6 top_heading">
          <div class="heading">
            <h2 style="color:#39353D;">My Reports</h2>
            <h3 style="color:#39353D;">Employee Dashboard</h3>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 mt-md-0 mt-2 emp_das_datepiker">
          <div class="d-flex d-md-flex align-items-center justify-content-end">
            <div class="date_toolbar_main">
              <div class="bg-white date-toolbar">
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-12 col-md-12 col-lg-6">
                        <div class="date">
                          <div class="heading_5">From</div>
                          <div class="text_black font_size_14 d-flex align-items-center">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                viewBox="0 0 17.5 17.5">
                                <path data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)" style="
                                    fill: none;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  " /></svg></span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.start_date"
                              :format="customFormatter" @selected="changeStartDate"
                              :disabledDates="disabledFutureDates"></datepicker>

                            <p class="min-date-value d-none">
                              {{ listConfig.start_date | formatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-6">
                        <div class="date todate pl_6 border-0">
                          <div class="heading_5">To</div>
                          <div class="text_black font_size_14 d-flex align-items-center">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                viewBox="0 0 17.5 17.5">
                                <path data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)" style="
                                    fill: none;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  " /></svg></span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.end_date"
                              :format="customFormatter" @selected="changeEndDate" :disabled="isDisabled"
                              :disabledDates="disabledDates"></datepicker>
                            <p class="max-date-value d-none">
                              {{ listConfig.end_date | formatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:" class="reset_btn" @click="resetDates">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <metadata>
                  Svg Vector Icons : http://www.onlinewebfonts.com/icon
                </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z">
                  </path>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table_card">
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
              <div class="dataTables_length mb-3 mb-lg-0" id="datatable_length">
                <label>Reports
                  <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                    @change="getProductsBysearch">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  entries</label>
              </div>
              <div id="datatable_filter" class="dataTables_filter">
                <label>Search:<input type="search" class="" placeholder="Type Of Assessment" aria-controls="datatable" v-model="listConfig.search_string" v-on:keyup.enter="getProductsBysearch" />
                  <a href="javascript:void(0)" @click="getProductsBysearch" class="search_icon slty_up"> <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </a>
                  <a href="javascript:void(0)" v-if="listConfig.search_string" @click=" listConfig.search_string = null; getProductsBysearch(); " class="crossicon x_icon">
                    X
                  </a>
                </label>
              </div>
            </div>
            <div class="table-responsive table_audit_log">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <th class="sort DOA">
                      <SortIcons :lable="'Date Of Assessment  '" :listConfig="listConfig" :sortBy="'assessment_date'" @sorting="sorting($event)" />
                    </th>
                    <th class="report_name">Name</th>
                    <th>Email Address</th>
                    <th class="sort DOA">
                      <SortIcons :lable="'Type Of Assessment'" :listConfig="listConfig" :sortBy="'type_of_assessment'" @sorting="sorting($event)" />
                    </th>
                    <th class="report_action">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, indx) in reports.records" :key="indx">
                    <td> <p> {{ report.formated_date ? report.formated_date : report.formated_assessment_date }} </p> </td>
                    <td> <p>{{ first_name }}</p> </td>
                    <td> <p class="email_tag"> <a :href="'mailto:' + report.user_email">{{ report.user_email }}</a> </p> </td>
                    <td> <p> {{ report.type_of_assessment ? report.type_of_assessment : "Not Available" }} </p> </td>
                    <td>
                      <div class="edit_btn">
                        <p>
                          <router-link :to="{ name: 'view_my_reports', params: { url: report.u_id }, }" target="_blank" title="View Report">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')" alt="img" />
                          </router-link>
                          &nbsp;
                          <a :href="'javascript:'" @click="downloadZipFile(report.summary_report, first_name,report.type_of_assessment)" title="Download">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/download.svg')" alt="img" />
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="data_length == 0">
                    <td colspan="5" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ positionText }}</p>
            <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
              :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import "vue-select/dist/vue-select.css";
  import AuthService from "@/services/AuthService";
  import $ from "jquery";
  import moment from "moment";
  import Datepicker from "vuejs-datepicker";
  import VPagination from "@/components/VPagination";
  import SortIcons from "@/components/SortingIcons";

  export default {
    name: "my_reports",
    components: {
      Datepicker,
      VPagination,
      SortIcons,
    },
    data() {
      return {
        show: false,
        reportAvailable: true,
        first_name: "",
        reports: [],
        isDisabled: true,
        disabledDates: {
          to: new Date(Date.now() - 8640000),
        },
        disabledFutureDates: {
          from: new Date(Date.now()),
        },
        currentPage: 1,
        itemsPerPage: 10,
        listConfig: {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          sortBy: "assessment_date",
          order: "DESC",
          search_string: null,
          start_date: null,
          end_date: null,
        },
        data_length: 0,
      };
    },
    computed: {
      positionText: function () {
        var endIndex = this.currentPage * this.itemsPerPage,
          startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
        if (this.data_length == 0) {
          startIndex = 0;
        }
        return (
          "Showing " +
          startIndex +
          " to " +
          (endIndex > this.data_length ? this.data_length : endIndex) +
          " of " +
          this.data_length
        );
      },
      btnText: function () {
        if (this.show) {
          return "";
        }
        return "";
      },
      pages() {
        if (this.data_length <= this.itemsPerPage) {
          return [1];
        }
        let pages_array = [
          ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
        ].map((e) => e + 1);
        return pages_array;
      },
    },
    watch: {
      currentPage(newValue) {
        this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
        this.getSingleUserReport();
      },
    },
    destroyed() {
      this.$parent.employeeDashboard = false;
    },
    mounted() {
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      this.listConfig = {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      };
      this.$parent.employeeDashboard = true;

      this.getSingleUserReport();
    },
    methods: {
      resetDates() {
        this.listConfig.end_date = "";
        this.listConfig.start_date = "";
        this.getSingleUserReport();
      },
      onPageChange(page) {
        console.log(page);
        this.currentPage = page;
      },
      sorting($event) {
        console.log($event);
        this.listConfig.sortBy == $event.sortBy;
        this.listConfig.order == $event.order;
        this.getSingleUserReport();
      },
      getProductsBysearch() {
        this.currentPage = 1;
        this.listConfig.limit = this.itemsPerPage;
        this.getSingleUserReport();
      },
      getSingleUserReport() {
        var _this = this;
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        _this.isLoader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/employee-report-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: _this.listConfig,
        };
        _this
          .axios(config)
          .then(({ data }) => {
            if (data.success) {
              _this.first_name = data.first_name;
              _this.reports = data;
              _this.data_length = data.counts;
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            } else {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              _this.$toast.error(data.message, {
                position: "top-right",
                duration: 5000,
              });
            }
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.data.type == false) {
              _this.$router.push({ name: "login" });
            }
          });
      },
      downloadZipFile(url, username, type_of_assessment) {
        var fileName ="report";
        if(type_of_assessment == "Self-Assessment"){
             fileName = username+"-self-assessment.html";
        }else if(type_of_assessment == "Ergo Eval"){
             fileName = username+"-ergo-eval.html";
        }
        AuthService.downloadZipFile(url, username, type_of_assessment).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          console.log(fileName);
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      },
      updateDatePicker() {
        // Extend dataTables search
        $.fn.dataTable.ext.search.push(function (settings, data) {
          var min = $(".min-date-value").html();
          var max = $(".max-date-value").html();
          var createdAt = data[0] || 0; // Our date column in the table
          if (
            min == "" ||
            max == "" ||
            (moment(createdAt).isSameOrAfter(min) &&
              moment(createdAt).isSameOrBefore(max))
          ) {
            return true;
          }
          return false;
        });
        this.getProductsBysearch();
      },
      changeStartDate() {
        this.$nextTick(() => {
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (
            this.listConfig.end_date != "" &&
            this.listConfig.end_date != null
          ) {
            this.updateDatePicker();
          }
        });
      },
      changeEndDate() {
        this.$nextTick(() => {
          this.updateDatePicker();
        });
      },
      customFormatter(date) {
        return moment(date).format("MMM DD, YYYY");
      },
    },
  };

  // function reload(){
  //    if (window.location.href.substr(-2) !== "?r") {
  //         window.location = window.location.href + "?r";
  //     }
  // }
  // setTimeout(reload, 100);
</script>